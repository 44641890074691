/* eslint-disable react/prop-types */
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getSiteUrl } from '@utils/config';
import HeroSection from '@components/Shared/HeroSection/HeroSection';
import SnippetSection from '@components/Shared/SnippetSection/SnippetSection';
import TextSectionRouter from '@components/Shared/TextSectionRouter/TextSectionRouter';
import ImageGrid from '@components/Shared/ImageGrid/ImageGrid';
import PageTransitionContainer from '@components/Shared/PageTransition/PageTransitionContainer';
import VideoSection from '@components/Shared/VideoSection/VideoSection';
import FaqCategoryBlock from '@components/Shared/FAQ/FaqCategoryBlock';
import WebForm from '@components/Shared/WebForm/WebForm';
import PreFooterSection from '@components/Shared/PreFooterSection/PreFooterSection';
import DynamicDisclaimer from '@components/Shared/Disclaimer/DynamicDisclaimer';
import { extractColors } from '@utils/utils';
import styles from './WebpageTemplate.module.css';
import ImageSlider from '@components/Shared/ImageSlider/ImageSlider';
import FeaturedHomes from '@components/Shared/FeaturedHomes/FeaturedHomes';
import CTASection from '@components/Shared/CTASection/CTASection';
import ThreeImageGridContainer from '@components/Shared/ThreeImageGrid/ThreeImageGridContainer';
import dynamic from 'next/dynamic';
import { groupPreFooterActions } from '@utils/webpageTemplate';

const EmbeddedSection = dynamic(
  () => import('@components/Shared/EmbeddedSection/EmbeddedSection'),
  {
    ssr: false,
  }
);

/**
 * Webpage Template used to dynamically display Contentful content on a page with shared components
 *
 * @param {{
 *  pageInfo: {
 *    contentType: string
 *    contentfulName: string
 *    disclaimer: string
 *    disclaimerBackgroundAndTextColors: string
 *    heroSection: {}
 *    mainContent: {
 *      contentType: string
 *      gridLayout?: string
 *      images?: []
 *      gridBackgroundColor?: string
 *    }[]
 *    metaDescription: string
 *    ogImage: { url: string }
 *    pageURL: string
 *    preFooterActions: { contentType: string }[]
 *    siteHeader: string
 *    titleTag: string
 *  }
 * }}
 */
// Supply domainUrls if you need to compare against something other than the chcom internalUrlVariants (for example careers domain).
// The prefooter CTA use this to determine internal link.
export function WebpageTemplate({ pageInfo, domainUrls = [] }) {
  const router = useRouter();

  let insetCtas = []; // Gather paired or triple inset CTAs in the main content section before displaying.

  return (
    <div className={styles['webpage-template']}>
      <Head>
        <title>{pageInfo?.titleTag}</title>
        <meta name="description" content={pageInfo?.metaDescription} />
        <link rel="canonical" href={`${getSiteUrl(router?.pathname)}`} />
        <meta
          property="og:image"
          content={
            pageInfo?.ogImage?.url ||
            getSiteUrl('/public-assets/images/clayton-logo.jpg')
          }
        />
      </Head>

      {pageInfo?.heroSection && (
        <HeroSection heroSection={pageInfo?.heroSection} />
      )}

      {pageInfo?.mainContent?.map((contentSection, i, mainContent) => {
        // If the insetCtas array has content and the next item is not an inset CTA, reset the local insetCtas. We only want consecutive inset CTA items.
        if (
          insetCtas.length > 0 &&
          contentSection.ctaSize != 'Inset Paired' &&
          contentSection.ctaSize != 'Inset Triple'
        ) {
          insetCtas = [];
        }

        switch (contentSection.contentType) {
          case 'textSection':
            return <TextSectionRouter {...contentSection} />;
          case 'snippetSection':
            return <SnippetSection section={contentSection} />;
          case 'pageTransition':
            return <PageTransitionContainer {...contentSection} />;
          case 'imageGrid':
            if (contentSection?.gridLayout == 'Three Images') {
              return (
                <ImageGrid
                  cards={contentSection?.images}
                  bgColor={contentSection?.gridBackgroundColor}
                />
              );
            } else if (
              contentSection?.gridLayout === 'Image Slider with Static Text'
            ) {
              return <ImageSlider section={contentSection} />;
            } else if (
              contentSection?.gridLayout === 'Three Images: Large-Small' ||
              contentSection?.gridLayout === 'Three Images: Small-Large'
            ) {
              return (
                <ThreeImageGridContainer mainContent={mainContent} index={i} />
              );
            } else {
              return <></>;
            }
          case 'videoSection':
            return <VideoSection {...contentSection} />;
          case 'faqSection':
            return (
              <FaqCategoryBlock
                section={contentSection}
                className="mx-auto w-4/5 max-w-[1400px] py-12"
              />
            );
          case 'featuredHomesSection':
            return <FeaturedHomes section={contentSection} />;
          case 'embeddedContent':
            return <EmbeddedSection section={contentSection} />;
          case 'cta':
            {
              let insetCtaReady = false;
              if (
                contentSection.ctaSize == 'Inset Paired' &&
                insetCtas.length < 2
              ) {
                insetCtas.push(contentSection);
                if (insetCtas.length == 2) {
                  insetCtaReady = true;
                }
              } else if (
                contentSection.ctaSize == 'Inset Triple' &&
                insetCtas.length < 3
              ) {
                insetCtas.push(contentSection);
                if (insetCtas.length == 3) {
                  insetCtaReady = true;
                }
              } else {
                // Display the non-paired/triple CTAs.
                // Note: keep CTASection for now because the crossmod page displays a CTA and it has a different format thatn the prefooter section CTA
                return <CTASection section={contentSection} />;
              }

              // Display Inset Paired/Triple CTAs when they are grouped and ready.
              if (insetCtaReady) {
                const ctas = insetCtas;
                insetCtas = [];
                insetCtaReady = false;
                return (
                  <div className={styles.preFooterCta}>
                    <PreFooterSection
                      preFooterActions={ctas}
                      domainUrls={domainUrls}
                    />
                  </div>
                );
              }
            }
            break;

          default:
            return null;
        }
      })}

      {groupPreFooterActions(pageInfo?.preFooterActions)?.map(
        (contentSection) => {
          switch (contentSection.contentType) {
            case 'webForm':
              return <WebForm webFormData={contentSection} />;
            case 'cta': {
              const actions = contentSection?.insetCtas
                ? contentSection.insetCtas
                : [contentSection];
              return (
                <div className={styles.preFooterCta}>
                  <PreFooterSection
                    preFooterActions={actions}
                    domainUrls={domainUrls}
                  />
                </div>
              );
            }
            default:
              return null;
          }
        }
      )}

      {pageInfo?.disclaimer && (
        <DynamicDisclaimer
          disclaimerContent={pageInfo?.disclaimer}
          backgroundColor={
            extractColors(pageInfo?.disclaimerBackgroundAndTextColors)?.[0]
          }
          textColor={
            extractColors(pageInfo?.disclaimerBackgroundAndTextColors)?.[1]
          }
        />
      )}
    </div>
  );
}
