import React, { useEffect } from 'react';
import styled from 'styled-components';

interface WistiaApi {
  embed?: (videoId: string, options?: object) => void;
  api?: (videoId: string) => void;
  Player?: {
    create: (videoId: string, options?: object) => void;
  };
  // Add other Wistia properties/methods as needed
}

declare global {
  interface Window {
    Wistia: WistiaApi;
  }
}

// Then you can use it like this instead of 'declare const Wistia: any;'
declare const Wistia: WistiaApi;

export const VideoStyles = styled.div`
  height: 360px;
  margin-top: 32px;
  position: relative;
  width: 640px;
`;

interface FaqAnswerVideoProps {
  videoId: string;
}

const FaqAnswerVideo = ({ videoId }: FaqAnswerVideoProps) => {
  useEffect(() => {
    let script: HTMLScriptElement | undefined;

    if (typeof document !== 'undefined' && typeof Wistia !== 'undefined') {
      script = document.createElement('script');
      script.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
      script.async = true;
      document.body.appendChild(script);
    }

    // Cleanup function to remove the script when component unmounts
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [videoId]);

  return (
    <VideoStyles className={`wistia_embed wistia_async_${videoId}`}>
      <div
        className="wistia_swatch"
        style={{
          height: '100%',
          left: '0',
          opacity: '0',
          overflow: 'hidden',
          position: 'absolute',
          top: '0',
          transition: 'opacity 200ms',
          width: '100%',
        }}
      >
        <img
          src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
          style={{
            filter: 'blur(5px)',
            height: '100%',
            objectFit: 'contain',
            width: '100%',
          }}
          alt=""
          aria-hidden="true"
          onLoad={(e) =>
            ((e.currentTarget.parentNode as HTMLElement).style.opacity = '1')
          }
        />
      </div>
    </VideoStyles>
  );
};

export default FaqAnswerVideo;
