import React, { useEffect, useReducer } from 'react';
import FaqQuestion from './FaqQuestion';
import { FaqCategoryBlockStyles } from './FaqCategoryBlock.styled';
import { getIdFromSlug } from '@utils/faqUtils';

interface WistiaApi {
  embed?: (videoId: string, options?: object) => void;
  api?: (videoId: string) => void;
  Player?: {
    create: (videoId: string, options?: object) => void;
  };
}

declare global {
  interface Window {
    Wistia: WistiaApi;
  }
}

declare const Wistia: WistiaApi;

type Faq = {
  active: boolean;
  slug: string;
  faqQuestion: string;
  faqAnswer?: string;
  faqBackgroundColor?: string;
  faqAnswerVideo?: Array<{
    embeddedContent: string;
  }>;
  answerLinks?: Array<{
    displayLinkAs: string;
    link: string;
    linkText: string;
  }>;
  disclaimer?: string;
};

interface FaqCategoryBlockProps {
  section: {
    sectionHeader?: string;
    sectionIntro?: string;
    categoryPageLink?: {
      link?: string;
    };
    faqs?: Array<Faq>;
  };
  setFaqInfo?: (faq: Faq | null) => void;
  canScroll?: boolean;
  className?: string;
}

interface FaqState {
  sectionHeader?: string;
  sectionIntro?: string;
  categoryPageLink?: {
    link?: string;
  };
  faqs?: Array<{
    active: boolean;
    slug: string;
    faqQuestion: string;
    faqAnswer?: string;
    faqBackgroundColor?: string;
    faqAnswerVideo?: Array<{
      embeddedContent: string;
    }>;
    answerLinks?: Array<{
      displayLinkAs: string;
      link: string;
      linkText: string;
    }>;
    disclaimer?: string;
  }>;
}

interface FaqAction {
  type: string;
  faqSlug?: string;
  payload?: FaqState;
}

function reducer(state: FaqState, action: FaqAction): FaqState {
  switch (action.type) {
    case 'activate_faq': {
      return {
        ...state,
        faqs:
          state?.faqs?.map((faq) => {
            faq.slug.includes((action.faqSlug ?? '').toLowerCase())
              ? (faq.active = true)
              : (faq.active = false);
            return faq;
          }) ?? [],
      };
    }
    case 'deactivate_faq': {
      return {
        ...state,
        faqs:
          state?.faqs?.map((faq) => {
            faq.active = false;
            return faq;
          }) ?? [],
      };
    }
    case 'replace_state': {
      if (!action.payload) {
        throw Error('Payload is required for replace_state action');
      }
      return action.payload;
    }
  }
  throw Error('Unknown action: ' + action.type);
}

const FaqCategoryBlock = ({
  section,
  setFaqInfo,
  canScroll = false,
  className,
}: FaqCategoryBlockProps) => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, section);

  useEffect(() => {
    const activeFaq = state?.faqs?.filter((faq) => faq.active)[0];

    // Handle scrolling down the page to the open FAQ
    if (activeFaq && canScroll) {
      const target = document.querySelector(
        `#${getIdFromSlug(activeFaq.slug.trim())}`
      );

      setTimeout(() => {
        window.scrollTo({
          top: (target?.getBoundingClientRect().top ?? 0) + window.scrollY,
          left: 0,
          behavior: 'smooth',
        });
      }, 100);
    }

    setFaqInfo?.(activeFaq || null);
  }, [state?.faqs]);

  // This replaces the state in the reducer when 'section' content changes. Otherwise, initial state of reducer does not change when faq sections change.
  // https://stackoverflow.com/questions/66790001/is-it-possible-to-change-the-initial-state-of-reducer-after-state-changed-and-us
  useEffect(() => {
    if (section) {
      dispatch({ type: 'replace_state', payload: section });
    }
  }, [section]);

  useEffect(() => {
    let script: HTMLScriptElement | undefined;

    if (typeof document !== 'undefined' && typeof Wistia === 'undefined') {
      script = document.createElement('script');
      script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <FaqCategoryBlockStyles className={className}>
      <div className="section-header">
        <h2>{section?.sectionHeader}</h2>
        <p>{section?.sectionIntro}</p>
      </div>
      <ul className="section-faqs">
        {section?.faqs?.map((faq, i) => {
          return (
            <li key={`faq-category-faq-${i + 1}`} className="faq-category-faq">
              <FaqQuestion
                faq={faq}
                categoryPageLink={section?.categoryPageLink?.link}
                dispatch={dispatch}
              />
            </li>
          );
        })}
      </ul>
    </FaqCategoryBlockStyles>
  );
};

export default FaqCategoryBlock;
