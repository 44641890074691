export const staticCategories = [
  {
    categorySlug: '/manufactured-home-buying',
    webPageKey: '4KNqJEP7khtO3eP9T1Z6id',
    faqCategory: 'Home Buying',
  },
  {
    categorySlug: '/manufactured-home-features',
    webPageKey: '7MERUrpgvMTOBFDIdKpYMG',
    faqCategory: 'Home Features',
  },
  {
    categorySlug: '/manufactured-home-delivery',
    webPageKey: '7vmm1DQSOZecFrJl0UBlwi',
    faqCategory: 'Home Delivery',
  },
  {
    categorySlug: '/manufactured-home-building',
    webPageKey: 'bKNwNxx5AqDSBWTX2oLfj',
    faqCategory: 'Home Building',
  },
  {
    categorySlug: '/manufactured-home-financing',
    webPageKey: '4iGLFs25OFkpLKFXhJysLd',
    faqCategory: 'Home Financing',
  },
  {
    categorySlug: '/manufactured-homeownership',
    webPageKey: '1NcUUresQX77BFMV8l4GUo',
    faqCategory: 'Homeownership',
  },
];

export const getCategorySlug = (categoryName) => {
  return staticCategories.filter((cat) => cat.faqCategory === categoryName)[0]
    ?.categorySlug;
};

// Build id from slug so the scrollTo feature works
export const getIdFromSlug = (slug) => {
  const firstChar = slug.charAt(0);
  const lastChar = slug.charAt(slug.length - 1);

  // Remove slashes at the start or end or just return the original
  if (firstChar === '/' && lastChar === '/') {
    return slug.slice(1, slug.length).slice(0, -1);
  }

  if (firstChar === '/') {
    return slug.slice(1, slug.length);
  }

  if (lastChar === '/') {
    return slug.slice(0, -1);
  }

  return slug;
};
