import styled from 'styled-components';
import { desktop_breakpoint_extended } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';

export const FaqCategoryBlockStyles = styled.div`
  .section-header {
    padding: 32px;

    @media screen and (min-width: ${desktop_breakpoint_extended}px) {
      padding-top: 0;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .section-faqs {
    border-top: 1px solid ${Colors.accent.grey4.standard};
    list-style: none;
  }

  @media screen and (min-width: ${desktop_breakpoint_extended}px) {
    margin-top: 48px;

    & + & {
      margin-top: 0;
    }
  }
`;
