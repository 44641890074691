import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

export const FaqQuestionStyles = styled.div`
  border-bottom: 1px solid ${Colors.accent.grey4.standard};
  padding: 32px;
  position: relative;

  h4 {
    margin-right: 32px;
    font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: -0.3px;
    }
  }

  a.faq-question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
  }

  a.faq-question:link,
  a.faq-question:visited {
    color: ${Colors.accent.grey1.standard};
  }

  .icon-open > div {
    height: 100%;
    position: relative;
    width: 16px;
  }

  svg {
    height: 24px;
    position: absolute;
    right: -6px;
    top: calc(50% - 12px);
    width: 24px;

    path {
      fill: ${Colors.primary.claytonBlue.standard};
    }
  }

  .faq-answer {
    display: none;
    margin-top: 32px;
  }

  .markdown {
    margin-right: 32px;

    a {
      display: inline;
      text-decoration: underline;
    }

    a:link,
    a:visited {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }

  .faq-answer-links {
    list-style: none;
    margin-top: 32px;

    li {
      display: inline-block;
    }

    li + li {
      margin-left: 16px;
    }

    .clayton-blue {
      margin-bottom: 0;
    }
  }

  .disclaimer {
    font-size: 12px;
    margin-top: 32px;
  }

  &.open {
    background-color: ${(props) => props.$styleProps.bgColor};

    .faq-answer {
      display: block;
    }
  }
`;
