import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import colors from 'pubweb-smokey/dist/colors';
import Button from '@components/Shared/Buttons/Button';
import PlusSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/plus.svg';
import MinusSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/minus.svg';
import Markdown from '../Markdown/Markdown';
import FaqAnswerVideo from './FaqAnswerVideo';
import {
  getSlug,
  isUrlInternal,
  extractColors,
  auditMarkdownLinks,
} from '@utils/utils';
import { getIdFromSlug } from '@utils/faqUtils';
import { FaqQuestionStyles } from './FaqQuestion.styled';

interface FaqProps {
  faq: {
    active: boolean;
    slug: string;
    faqQuestion: string;
    faqAnswer?: string;
    faqBackgroundColor?: string;
    faqAnswerVideo?: Array<{
      embeddedContent: string;
    }>;
    answerLinks?: Array<{
      displayLinkAs: string;
      link: string;
      linkText: string;
    }>;
    disclaimer?: string;
  };
  categoryPageLink?: string;
  dispatch: (action: { type: string; faqSlug?: string }) => void;
}

const FaqQuestion = ({ faq, categoryPageLink, dispatch }: FaqProps) => {
  const [isOpen, setIsOpen] = useState(faq.active);

  // Build the categorySlug so the FAQ links work
  const lastChar = categoryPageLink?.charAt(categoryPageLink.length - 1);
  const categorySlug =
    lastChar === '/' ? categoryPageLink?.slice(0, -1) : categoryPageLink;

  const questionBgColor =
    extractColors(faq?.faqBackgroundColor) || colors.accent.veryLightBlue;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    // Dispatch different actions depending on open state
    isOpen
      ? dispatch({ type: 'deactivate_faq' })
      : dispatch({ type: 'activate_faq', faqSlug: faq.slug });

    // Use pushState to update url without reloading the page
    if (categorySlug) {
      window.history.pushState(
        '',
        '',
        isOpen ? `${categorySlug}/` : `${categorySlug}${faq.slug}`
      );
    }
  };

  useEffect(() => {
    setIsOpen(faq.active);
  }, [faq.active]);

  useEffect(() => {
    auditMarkdownLinks('faq-answer');
  }, []);

  return (
    <FaqQuestionStyles
      id={getIdFromSlug((faq.slug ?? '').trim())}
      className={isOpen ? 'open' : null}
      $styleProps={{ bgColor: questionBgColor }}
    >
      <a
        href={categorySlug ? `${categorySlug}${faq.slug ?? ''}` : '#'}
        className="faq-question"
        onClick={handleClick}
      >
        <h4>{faq.faqQuestion && <Markdown markdown={faq.faqQuestion} />}</h4>{' '}
        <div className="icon-open">
          <div>
            {isOpen ? (
              <MinusSvg viewBox="0 0 16 16" />
            ) : (
              <PlusSvg viewBox="0 0 16 16" />
            )}
          </div>
        </div>
      </a>
      <div className="faq-answer">
        {faq.faqAnswer && <Markdown markdown={faq.faqAnswer} />}
        {(faq.faqAnswerVideo?.length ?? 0) > 0 && (
          <FaqAnswerVideo
            videoId={faq.faqAnswerVideo?.[0]?.embeddedContent ?? ''}
          />
        )}
        {(faq.answerLinks?.length ?? 0) > 0 && (
          <ul className="faq-answer-links">
            {faq.answerLinks?.map((answerLink, i) => (
              <li key={`faq-answer-link-${i + 1}`}>
                {answerLink.displayLinkAs === 'Button' ? (
                  <Button
                    isCompact={true}
                    text={answerLink.linkText}
                    type="primary"
                    navigateUrl={
                      isUrlInternal(answerLink.link)
                        ? getSlug(answerLink.link)
                        : answerLink.link
                    }
                    nextJSUrl={
                      isUrlInternal(answerLink.link)
                        ? getSlug(answerLink.link)
                        : answerLink.link
                    }
                    target="_self"
                    fillContainer={false}
                    icon={null}
                    linkComponent={isUrlInternal(answerLink.link) ? Link : null}
                    disabled={false}
                    onButtonClick={() => {}}
                    buttonID={`faq-answer-link-${i + 1}`}
                    automationId={`faq-answer-link-${i + 1}`}
                  >
                    {answerLink.linkText}
                  </Button>
                ) : isUrlInternal(answerLink.link) ? (
                  <Link href={answerLink.link}>{answerLink.linkText}</Link>
                ) : (
                  <a href={answerLink.link}>{answerLink.linkText}</a>
                )}
              </li>
            ))}
          </ul>
        )}
        {faq.disclaimer && <p className="disclaimer">{faq.disclaimer}</p>}
      </div>
    </FaqQuestionStyles>
  );
};

export default FaqQuestion;
